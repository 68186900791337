.footer{
    bottom: 0;
    left: 0;
    width: 100%;
    background: #262a31;
    background-image: url('../img/fond.png');
    text-align: center;
    color: #f4f4f4;
    border-top: black solid 5px;
}

.icons{
    padding-top: 10px;
    padding-bottom: 10px;
}

.icons a{
    text-decoration: none;
    font-size: 2rem;
    margin: 0.5rem;
    color: #f4f4f4;
}
.icons img{
    height: 50px;
}
.fo-links{
    display: flex;
    justify-content: center;
}

.fo-links li{
    transition: .5s;
  }
  
  .fo-links li:hover{
    filter: invert(.5);
  }
.company-name{
    font-size: 1.6rem;
    margin-top: 0.5rem;
}

@media (max-width: 500px){
    html{
        font-size: 50%;
    }
}    

@media(min-width: 501px) and (max-width: 768px){
    html{
    font-size: 50%;
    }
}    