.nav{
    position: relative;
    display: flex;
    list-style: none;
    margin: 0;
    z-index: 100;
  }
  a {
    display: block;
    position: relative;
    text-decoration: none;
    color: #999;
    transition: all .5s;
  }
  
  a:hover{
    color: #FFF;
  }
 .nav a::after{
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: '.';
    color: transparent;
    background: rgb(59, 59, 59);
    height: 2px;
    transition: all .5s;
  }

  .nav a:hover::after{
    width: 80%;
    background: rgb(255, 255, 255);
  }
  li {
    display: inline-block;
    transition: all .5s;
    margin: 5px;
    padding: 15px 10px;
  }
  
  li.active{
    color: #FFF;
  }
  li.active:hover{
    color: rgb(92, 92, 92);
  }

  
  ul{
    display: flex;
    justify-content: space-around;

    list-style: none;
    padding: 0;
    color: #262a31;
  }
  
  